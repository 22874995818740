import * as React from "react"
import Layout from "../components/Layout"
import Content from "../components/Content"
import { graphql } from "gatsby"

// markup
const WorkPageTemplate = ({ data }) => {
  return (
    <Layout title="Home">
      <Content page="home" data={data} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulProjekt(slug: { eq: $slug }) {
      title
      content {
        __typename
        ... on ContentfulContentTextBild {
          headline
          isTextSmall
          textTwoColumns
          text {
            raw
          }
          imagePosition
          image {
            file {
              url
            }
            title
            gatsbyImageData(
              width: 1000
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
    }
  }
`

export default WorkPageTemplate
